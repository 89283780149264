import React from 'react';

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

const JobPage = () => {
  return (
    <>
      <SEO title="Technolog" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Technolog</h1>
          <p className={styles.text}>
            NutritionPro je jedním z nejrychleji rostoucích Foodtech startupů v ČR (500 %+ meziroční růst). 
          </p>
          <p className={styles.text}>
            Přišli jsme na trh s námi vyvinutým algoritmem, který na základě údajů o Tvém těle je schopen vytvořit jídelníček přímo Tobě na míru. Tím to ale nekončí – jídlo pro Tebe uvaříme a dovezeme až ke dveřím!
          </p>
          <p className={styles.text}>
            <a href="https://forbes.cz/miliardar-janecek-veri-budoucnosti-zdraveho-jidla-do-startupu-nutritionpro-investuje-spolu-s-dalsimi-35-milionu/" target="_blank">Nedávno jsme obdrželi investici</a> pro další expanze, a proto hledáme <b>talentovaného Technologa</b>.
          </p>

          <p className={styles.text}>
            Odvádíme kvalitní a smysluplnou práci, která vede ke spokojenosti všech našich klientů. Jsme tým mladých lidí, který k sobě hledá nové kolegy s podobným „mindsetem“. Pokud to máš stejně, tak ti zaručíme tři věci: stabilitu, potenciál růstu a práci s unikátním produktem.
          </p>

          <p className={styles.text}>
            Jestli chceš být součástí nadšeného týmu a rozšířit supercrew úspěšného startupu, baví tě komunikace s lidmi a nechceš si nechat ujít šanci a příležitost skočit do nejžhavějšího startupového odvětví, FoodTech, tak čti dál!
          </p>

          <h3 className={styles.textSubHeader}>
          Co u nás budeš dělat?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
            Ve spoluprácí s šéfkuchařem vytvářet receptury odpovídající nárokům velkokapacitních kuchyní.
            </li>
            <li className={styles.newList}>
            Zefektivňovat vnitřní procesy v kuchyních ve spolupráci s kontrolorem kvality.
            </li>
            <li className={styles.newList}>
            Vytvářet strategie rozvoje technologických procesů ve výrobě pokrmů pro udržování a zvyšování kvality při růstu objemu výroby.
            </li>
            <li className={styles.newList}>
            Udržovat ekonomiku celého produktu.
            </li>
            <li className={styles.newList}>
            Vyhodnocovat realizace receptur ve velkém objemu s ohledem rentability a hlavně kvality pro zákazníka.
            </li>
          </ul>
          <h3 className={styles.textSubHeader}>
          Co od tebe očekáváme?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
            Na vysoké úrovni se vyznáš v technologických procesech přípravy jídel
            </li>
            <li className={styles.newList}>
            Velmi dobrou orientaci v potravinářství a zbožíznalství, dokážeš jednoduše spočítat foodcost 
            </li>
            <li className={styles.newList}>
            Prokazatelné zkušenosti na obdobné pozici
            </li>
            <li className={styles.newList}>
            Zájem o obor a nové trendy v gastronomii, nápaditost, kreativita
            </li>
            <li className={styles.newList}>
            Znalost hygienických pravidel a HACCP
            </li>
            <li className={styles.newList}>
            SŠ, VŠ potravinářského směru
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>Motivační finanční ohodnocení a měsíční bonus</li>
            <li className={styles.newList}>Plně hrazené vzdělávací kurzy a pravidelná konzultace s předními odborníky v oboru</li>
            <li className={styles.newList}>
            Firemní obědy zdarma
            </li>
            <li className={styles.newList}>
            Multisport kartu plně hrazenou firmou
            </li>
            <li className={styles.newList}>Vodu, kávu, čaj, džusy, čerstvé ovoce na pracovišti dle libosti</li>
            <li className={styles.newList}>
            Posilovna a lekce jógy přímo v kanceláři zdarma
            </li>
            <li className={styles.newList}>
            Sick-days
            </li>
          </ul>

          <p className={styles.text}>
            Sídlíme v moderních kancelářích v srdci Karlína, takže mimo jiné dostaneš i skvělé prostředí pro navázání kontaktů, neformální atmosféru bez dress code a velkou terasu s výhledem na Prahu!
          </p>

          <h3 className={styles.textSubHeader}>
            Těšíme se na tvoje CV a rádi Tě uvítáme v našem týmu NutritionPro!
          </h3>
          <p className={styles.text}>Kontakty:</p>
          <a className={styles.text} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          {/* <a className={styles.text} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_Sales" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
